<template>
  <b-row>
    <b-col cols="12">
      <b-card :title="companyData.title">
        <div class="font-medium-1 mb-1">
          <span class="font-weight-bold">VN:</span> {{ companyData.tax_no }} <span class="font-weight-bold">VD:</span> {{ companyData.tax_office }}
        </div>
        <div class="font-medium-1 mb-1">
          <FeatherIcon icon="MapPinIcon" />
          {{ companyData.address }}
        </div>
        <div class="font-medium-1 mb-1">
          <FeatherIcon icon="PhoneIcon" />
          +90{{ companyData.phone }}
        </div>
        <div
          v-if="companyData.fax"
          class="font-medium-1 mb-1"
        >
          <FeatherIcon icon="PrinterIcon" />
          +90{{ companyData.fax }}
        </div>
        <div class="font-medium-1 mb-1">
          <FeatherIcon icon="MailIcon" />
          <a :href="'mailto:' + companyData.email"> {{ companyData.email }}</a>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'CompanyView',
  components: {
    BCard,
    BRow,
    BCol,
  },
  computed: {
    companyData() {
      return this.$store.getters['company/getCompany']
    },
  },
  created() {
    this.getCompany()
  },
  methods: {
    getCompany() {
      this.$store.dispatch('company/companyView')
    },
  },
}
</script>
